import React, { useEffect, memo, useState } from "react";
import Head from "next/head";
import { useRouter } from "next/router";

//import EventParticipants from '../components/SingleEvents/EventParticipants';
import HeroBanner from "../../components/events/EventsHero";
import BannerSmall from "../../components/events/BannerSmall";
import AboutArea from "../../components/events/AboutArea";
import EventTimeline from "../../components/events/EventTimeline";
import EventTimerJoinWidget from "../../components/events/EventTimerJoinWidget";
import EventsTargets from "../../components/events/EventsTargets";
import EventsNotTargets from "../../components/events/EventsNotTargets";
import LearningTools from "../../components/events/LearningTools";
import BrandsImpacted from "../../components/events/BrandsImpacted";
import Mentors from "../../components/events/Mentors";
import Faq from "../../components/events/Faqs";
import Testimonials from "../../components/events/Testimonials";
import ImageTestimonials from "../../components/events/ImageTestimonials";
import VideoTestimonials from "../../components/events/VideoTestimonials";
import BottomBanner from "../../components/events/BottomBanner";
import Curriculum from "../../components/events/Curriculum";
import StatsComponent from "../../components/events/StatsComponent";
import Bonuses from "../../components/events/Bonus";
import { BASE_URL } from "../../constants";
import MeetMentor from "../../components/programs/MeetMentor";
import axios from "axios";

import { pagedata as pageDataNew } from "../../data/webinar/ayushi";
import moment from "moment";

import * as fbq from "../../lib/fpixel";

// Utility function to check if an object is empty
const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};

const SingleEvents = memo(() => {
  const [pagedata, setPageData] = useState(pageDataNew);
  const [seatsLeft, setSeatsLeft] = useState(
    pagedata.heroData.eventDetails.seatsLeft
  );
  const [cohortData, setCohortData] = useState(undefined);

  useEffect(() => {
    localStorage.setItem("event_slug", pagedata.slug);
    console.log("SingleEvents mounted");
    return () => {
      console.log("SingleEvents unmounted");
    };
  }, []);

  useEffect(() => {
    fbq.sendWebinarEvent("ViewContent", pagedata);
  }, []);

  const updateSeatsLeft = async () => {
    const response = await axios.get(
      "https://api.toppersnotes.com/web/v1/events/available-slots?event_slug=" +
        pagedata.slug
    );
    const data = await response.data.data;

    pagedata.heroData.eventDetails.seatsLeft = data.remaining;
    setPageData(pagedata);
    setSeatsLeft(data.remaining);
  };

  const getCohortData = async () => {
    try {
      const response = await axios.get(
        "https://api.toppersnotes.com/web/v1/events/cohort-data?event_slug=" +
          pagedata.slug
      );

      if (response.data && response.data.data && response.data.data.cohort) {
        const cohort = await response.data.data.cohort;

        const webinar = await response.data.data.webinar;
        pagedata.heroData.eventDetails.salePrice = webinar.offer_price;
        pagedata.heroData.eventDetails.originalPrice = webinar.price;
        pagedata.defaultCoupon = webinar.default_coupon_code;

        pagedata.heroData.eventDetails.registerTill = moment(
          cohort.last_date_of_registration
        ).format("DD/MM/YYYY HH:mm:ss");
        pagedata.heroData.eventDetails.numberOfDays = cohort.no_of_days;
        pagedata.heroData.eventDetails.startDate = moment(
          cohort.start_date
        ).format("Do MMMM, YYYY");

        let eventChips = pagedata.heroData.eventDetails.eventChips;
        eventChips[2].heading = moment(cohort.start_date).format("Do MMMM");
        eventChips[0].heading = `${cohort.no_of_days} Days`;
        pagedata.heroData.eventDetails.eventChips = eventChips;

        setPageData(pagedata);

        localStorage.setItem(
          "pageData_" + pagedata.slug,
          JSON.stringify(pagedata)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setSeatsLeft(pagedata.heroData.eventDetails.seatsLeft);

    updateSeatsLeft();
    getCohortData();
  }, []);

  console.log(pagedata.numbers);
  const brands = pagedata.brands.content || {};

  const router = useRouter();
  const { theme } = router.query;

  // Set pagedata.theme.style based on query parameter
  if (theme) {
    pagedata.theme.style = theme === "dark" ? "dark" : "light";
  }

  return (
    <React.Fragment>
      {/* <SimpleBanner /> */}
      <Head>
        <title>Event - {pagedata.typingText}</title>
        <meta property="og:title" content={pagedata.typingText} />
        <meta property="og:description" content={pagedata.typingText} />
        <meta
          property="og:image"
          content={pagedata.heroData.eventDetails.influencerBannerImage}
        />
        <meta property="og:url" content={BASE_URL} />
      </Head>
      <div id="single-events">
        <HeroBanner
          data={pagedata.heroData}
          theme={pagedata.theme}
          slug={pagedata.slug}
        />
      </div>
      {!isEmptyObject(pagedata.brands) && (
        <BrandsImpacted data={pagedata.brands} theme={pagedata.theme} />
      )}
      {!isEmptyObject(pagedata.numbers) && (
        <BannerSmall data={pagedata.numbers} theme={pagedata.theme} />
      )}
      {!isEmptyObject(pagedata.benefits) && (
        <StatsComponent data={pagedata.benefits} theme={pagedata.theme} />
      )}
      {!isEmptyObject(pagedata.about) && (
        <AboutArea data={pagedata.about} theme={pagedata.theme} />
      )}
      {!isEmptyObject(pagedata.superMentorData) && (
        <MeetMentor data={pagedata.superMentorData} theme={pagedata.theme} />
      )}
      {!isEmptyObject(pagedata.mentors) && (
        <Mentors data={pagedata.mentors} theme={pagedata.theme} />
      )}

      {!isEmptyObject(pagedata.curriculum) && (
        <Curriculum data={pagedata.curriculum} theme={pagedata.theme} />
      )}
      {!isEmptyObject(pagedata.timeline) && (
        <EventTimeline data={pagedata.timeline} theme={pagedata.theme} />
      )}
      {!isEmptyObject(pagedata.heroData?.eventDetails) && (
        <EventTimerJoinWidget
          data={pagedata.heroData.eventDetails}
          theme={pagedata.theme}
          slug={pagedata.slug}
        />
      )}
      {!isEmptyObject(pagedata.bonuses) && (
        <Bonuses data={pagedata.bonuses} theme={pagedata.theme} />
      )}
      {!isEmptyObject(pagedata.targets) && (
        <EventsTargets data={pagedata.targets} theme={pagedata.theme} />
      )}
      {!isEmptyObject(pagedata.nottargets) && (
        <EventsNotTargets data={pagedata.nottargets} theme={pagedata.theme} />
      )}
      {!isEmptyObject(pagedata.heroData?.eventDetails) && (
        <EventTimerJoinWidget
          data={pagedata.heroData.eventDetails}
          theme={pagedata.theme}
          slug={pagedata.slug}
        />
      )}
      {!isEmptyObject(pagedata.learninggoals) && (
        <LearningTools data={pagedata.learninggoals} theme={pagedata.theme} />
      )}
      {!isEmptyObject(pagedata.videotestimonials) && (
        <VideoTestimonials
          data={pagedata.videotestimonials}
          theme={pagedata.theme}
        />
      )}
      {!isEmptyObject(pagedata.imagetestimonials) && (
        <ImageTestimonials
          data={pagedata.imagetestimonials}
          theme={pagedata.theme}
        />
      )}
      {!isEmptyObject(pagedata.texttestimonials) && (
        <Testimonials data={pagedata.texttestimonials} theme={pagedata.theme} />
      )}
      {!isEmptyObject(pagedata.heroData?.eventDetails) && (
        <EventTimerJoinWidget
          data={pagedata.heroData.eventDetails}
          theme={pagedata.theme}
          slug={pagedata.slug}
        />
      )}
      {!isEmptyObject(pagedata.heroData?.eventDetails) && (
        <BottomBanner
          data={pagedata.heroData.eventDetails}
          theme={pagedata.theme}
          slug={pagedata.slug}
        />
      )}
      {!isEmptyObject(pagedata.faqs) && (
        <Faq data={pagedata.faqs} theme={pagedata.theme} />
      )}
    </React.Fragment>
  );
});

export default SingleEvents;
